import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { apiLogout, apiValidateToken } from "./api/auth.api";

// Helper function to get a cookie value by name
const GetCookie = (name) => {
	return Cookies.get(name);
};

// Helper function to remove a cookie value by name
const RemoveToken = (name) => {
	Cookies.remove(name);
};

// Validate the token on the server and return the user information
const ValidateToken = async (token) => {
	const tokenResponse = await apiValidateToken(token);
	if (tokenResponse.status === "success") {
		const decodedToken = jwtDecode(token);
		return { status: tokenResponse.status, message: tokenResponse.message, token: decodedToken };
	} else {
		RemoveToken("jwt");
		return tokenResponse;
	}
};

// Logout the user by removing the token
const Logout = async (token) => {
	const logoutResponse = await apiLogout(token);
	RemoveToken("jwt");
	return logoutResponse;
};

// Export the helper functions
const AuthService = {
	GetCookie,
	RemoveToken,
	ValidateToken,
	Logout,
};

export default AuthService;
