export const fetchFromAPI = async (endpoint, options) => {
	let BASE_URL = "https://tools_api.mooseboxx.com";

	// If we are in development mode, use the local backend server
	if (import.meta.env.VITE_RELEASE === "DEV") {
		BASE_URL = import.meta.env.VITE_BACKEND_API;
	}

	// Set the URL to Base URL + endpoint
	const url = `${BASE_URL}${endpoint}`;

	try {
		const response = await fetch(url, options);
		const data = await response.json();
		if (data) {
			if (import.meta.env.VITE_RELEASE === "DEV") {
				if (endpoint !== "/status" && endpoint !== "/api/auth/token") {
					console.log(url, data);
				}
			}
			return data;
		} else {
			throw new Error("Could not reach backend server");
		}
	} catch {
		return {
			status: "error",
			message: "Could not reach backend server",
		};
	}
};

export const sendAPIRequest = async (endpoint, method, body = null, token = null) => {
	const headers = {
		"Content-Type": "application/json",
	};
	if (token) {
		headers["Authorization"] = `Bearer ${token}`;
	}
	let options = {
		method: method,
		headers: headers,
	};
	if (body) {
		options.body = JSON.stringify(body);
	}

	return fetchFromAPI(endpoint, options);
};
