import { AppShell, Burger, Button, Group, Image, LoadingOverlay, NavLink, Text, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCreditCardPay, IconKey, IconLogout, IconMovie, IconTools } from "@tabler/icons-react";
import { jwtDecode } from "jwt-decode";
import { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./css/App.css";
import AuthContext from "./context/auth.context";
import AuthService from "./services/auth.service";
import { startToast, updateToast } from "./services/toast.service";

// Pages
const LoginPage = lazy(() => import("./pages/LoginPage"));
const PasswordResetPage = lazy(() => import("./pages/PasswordResetPage"));
const PlexPage = lazy(() => import("./pages/PlexPage"));
const WebSiteDownPage = lazy(() => import("./pages/WebSiteDownPage"));
const Page404 = lazy(() => import("./pages/404Page"));

// Authenticated Pages
const AuthToolsPage = lazy(() => import("./pages/private/withAuthToolsPage"));
const AuthProfilePage = lazy(() => import("./pages/private/withAuthProfilePage"));
const AuthTransactionsPage = lazy(() => import("./pages/private/withAuthTransactionsPage"));

function App() {
	const [token, setToken] = useState(AuthService.GetCookie("jwt"));
	const [authLabel, setAuthLabel] = useState("Login");
	const [role, setRole] = useState("user");

	const navigate = useNavigate();
	const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
	const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

	// Nav Elements
	const navTitle = "Pages and Tools";

	// If user clicks the logout button, this function will be called
	const handleLogout = async () => {
		const thisToast = startToast("Logging out...");
		const currentToken = AuthService.GetCookie("jwt");
		if (currentToken) {
			const logoutResponse = await AuthService.Logout(currentToken);
			if (logoutResponse.status === "success") {
				updateToast(thisToast, logoutResponse.message, "success");
			} else {
				updateToast(thisToast, logoutResponse.message, "error");
			}
		}
		navigate("/");
		toggleMobile();
		setToken(null);
		setAuthLabel("Login");
		setRole("user");
	};

	// This effect will run whenever the token changes
	useEffect(() => {
		if (token) {
			const decodedToken = jwtDecode(token);
			setAuthLabel(`${decodedToken.name}'s Profile`);
			setRole(decodedToken.role);
		} else {
			setToken(null);
			setAuthLabel("Login");
			setRole("user");
		}
	}, [token]);

	return (
		<div className="App">
			<AuthContext.Provider value={{ setToken }}>
				<AppShell
					header={{ height: { base: 48, sm: 60, lg: 76 } }}
					navbar={{
						width: 300,
						breakpoint: "sm",
						collapsed: {
							mobile: !mobileOpened,
							desktop: !desktopOpened,
						},
					}}
					footer={{
						height: { base: 22 },
						styles: {
							textAlign: "center",
						},
					}}
					padding={{ base: "md", sm: "lg" }}
				>
					<AppShell.Header>
						<Group h="100%" px="md">
							<Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
							<Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
							<Image
								radius="md"
								h={{ base: 32, sm: 40, lg: 56 }}
								w="auto"
								fit="contain"
								src="web-app-manifest-512x512.png"
								href="/"
								onClick={() => {
									navigate("/");
								}}
							/>
						</Group>
					</AppShell.Header>
					<AppShell.Navbar p="md">
						<AppShell.Section>{navTitle}</AppShell.Section>
						<AppShell.Section grow my="md">
							<NavLink
								label={authLabel}
								href={token ? "/profile" : "/login"}
								leftSection={
									<IconKey
										style={{
											width: rem(16),
											height: rem(16),
										}}
										stroke={1.5}
									/>
								}
							></NavLink>
							<NavLink
								label="Plex Media Server"
								href="/"
								leftSection={
									<IconMovie
										style={{
											width: rem(16),
											height: rem(16),
										}}
										stroke={1.5}
									/>
								}
							></NavLink>
							<NavLink
								label="Micro Center Transactions"
								href="/transactions"
								leftSection={
									<IconCreditCardPay
										style={{
											width: rem(16),
											height: rem(16),
										}}
										stroke={1.5}
									/>
								}
							></NavLink>
							{role === "admin" && (
								<NavLink
									label="Tools Page"
									href="/tools"
									leftSection={
										<IconTools
											style={{
												width: rem(16),
												height: rem(16),
											}}
											stroke={1.5}
										/>
									}
								></NavLink>
							)}
						</AppShell.Section>
						<AppShell.Section>
							{token && (
								<Button
									label="Logout"
									variant="gradient"
									gradient={{
										from: "red.5",
										to: "red.9",
										deg: 45,
									}}
									onClick={handleLogout}
									mt={"auto"}
									leftSection={
										<IconLogout
											style={{
												width: rem(16),
												height: rem(16),
											}}
											stroke={1.5}
										/>
									}
								>
									Logout
								</Button>
							)}
						</AppShell.Section>
						<AppShell.Section>
							<Text align="center" size="sm" mt={"md"}>
								© {new Date().getFullYear()} - MooseBoxx
							</Text>
							<Text align="center" size="sm">
								All Rights Reserved
							</Text>
						</AppShell.Section>
					</AppShell.Navbar>
					<AppShell.Main>
						<Suspense
							fallback={
								<LoadingOverlay
									visible={true}
									zIndex={1000}
									overlayProps={{ radius: "lg", blur: 3 }}
									loaderProps={{
										color: "blue",
										type: "bars",
									}}
								/>
							}
						>
							<Routes>
								<Route path="/" element={<PlexPage />} />
								<Route path="/plex" element={<PlexPage />} />

								<Route path="/login" element={<LoginPage />} />
								<Route path="/reset-password" element={<PasswordResetPage />} />

								<Route path="/profile" element={<AuthProfilePage />} />

								<Route path="/transactions" element={<AuthTransactionsPage />} />

								<Route path="/tools" element={<AuthToolsPage />} />

								<Route path="/web-site-down" element={<WebSiteDownPage />} />

								{/* Catch all unmatched routes */}
								<Route path="/404" element={<Page404 />} />
								<Route path="/*" element={<Page404 />} />
							</Routes>
						</Suspense>
					</AppShell.Main>
				</AppShell>
			</AuthContext.Provider>
		</div>
	);
}

export default App;
