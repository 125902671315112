import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "./css/index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<MantineProvider
			defaultColorScheme="auto"
			theme={{
				colors: {
					dark: [
						"#C9C9C9", // Headings, Text and Icon outlines // Default: #C9C9C9
						"#b8b8b8", // Default: #b8b8b8
						"#828282", // Paragraphs // Default: #828282
						"#696969", // Placeholder text // Default: #696969
						"#424242", // Outline // Default: #424242
						"#3b3b3b", // Default: #3b3b3b
						"#2e2e2e", // Field Fill // Default: #2e2e2e
						"#242424", // Background // Default: #242424
						"#1f1f1f", // Default: #1f1f1f
						"#141414", // Default: #141414
					],
					light: [
						"#C9C9C9", // Headings, Text and Icon outlines // Default: #C9C9C9
						"#b8b8b8", // Default: #b8b8b8
						"#828282", // Paragraphs // Default: #828282
						"#696969", // Placeholder text // Default: #696969
						"#424242", // Outline // Default: #424242
						"#3b3b3b", // Default: #3b3b3b
						"#2e2e2e", // Field Fill // Default: #2e2e2e
						"#242424", // Background // Default: #242424
						"#1f1f1f", // Default: #1f1f1f
						"#141414", // Default: #141414
					],
				},
				primaryShade: {
					light: 6,
					dark: 8,
				},
				fontFamily: "-apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, Apple Color Emoji",
				fontFamilyMonospace: "ui-monospace, SFMono-Regular, Monaco, Liberation Mono, Courier New, monospace",
				defaultGradient: {
					from: "teal",
					to: "blue",
					deg: 45,
				},
				autoContrast: true,
				luminanceThreshold: 0.3,
				defaultRadius: "xl",
				fontSizes: {
					xs: "calc(0.75rem * var(--mantine-scale))",
					sm: "calc(0.875rem * var(--mantine-scale))",
					md: "calc(1rem * var(--mantine-scale))",
					lg: "calc(1.125rem * var(--mantine-scale))",
					xl: "calc(1.25rem * var(--mantine-scale))",
				},
				shadows: {
					md: "0 4px 12px rgba(0, 0, 0, 0.12)",
					lg: "0 10px 30px rgba(0, 0, 0, 0.12)",
					xl: "0 20px 40px rgba(0, 0, 0, 0.12)",
				},
				spacing: {
					xs: "calc(0.5rem * var(--mantine-scale))",
					sm: "calc(0.75rem * var(--mantine-scale))",
					md: "calc(1rem * var(--mantine-scale))",
					lg: "calc(1.5rem * var(--mantine-scale))",
					xl: "calc(2rem * var(--mantine-scale))",
				},
			}}
		>
			<App />
		</MantineProvider>
		<ToastContainer
			stacked
			position="bottom-right"
			autoClose={3000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			draggable
			theme="dark"
			pauseOnFocusLoss={false}
			pauseOnHover={false}
		/>
	</BrowserRouter>
);
