// Auth context provider for user authentication
// This context provider wraps the entire application and provides the current user and token to all components

import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [token, setToken] = useState(null);

	useEffect(() => {
		const token = AuthService.GetCookie("jwt");
		setToken(token);
		if (token) {
			AuthService.ValidateToken(token).then((response) => {
				if (response.token) {
					setCurrentUser(response.token);
				} else {
					setCurrentUser(null);
				}
			});
		}
	}, []);

	return <AuthContext.Provider value={{ currentUser, token }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
