import { toast } from "react-toastify";

export const updateToast = (thisToast, message, type) => {
	let autoCloseTime = 2500;
	if (type === "success") {
		autoCloseTime = 1500;
	}
	toast.update(thisToast, {
		render: message,
		type: type,
		autoClose: autoCloseTime,
	});
};

export const startToast = (message) => {
	toast.dismiss();
	return toast(message, {
		type: "info",
		autoClose: 5000,
	});
};
