import { sendAPIRequest } from "../fetch.service";

/////////////////////////
// Public API Routes
/////////////////////////
export const apiLogin = (email, password) => {
	return sendAPIRequest("/auth/login", "POST", { email, password });
};

export const apiRegister = (name, email, password) => {
	return sendAPIRequest("/auth/register", "POST", { name, email, password });
};

export const apiForgotPassword = (email) => {
	return sendAPIRequest("/auth/forgot_password", "POST", { email });
};

export const apiResetPassword = (password, token) => {
	return sendAPIRequest("/auth/reset_password", "PATCH", { password, token });
};

export const apiVerifyResetToken = (token) => {
	return sendAPIRequest("/auth/verify_reset_token", "GET", null, token);
};

/////////////////////////
// Private API Routes
/////////////////////////
export const apiLogout = (token) => {
	return sendAPIRequest("/api/auth/logout", "POST", null, token);
};

export const apiUpdatePassword = (old_password, new_password, token) => {
	return sendAPIRequest("/api/auth/update_password", "PATCH", { old_password, new_password }, token);
};

export const apiValidateToken = (token) => {
	return sendAPIRequest("/api/auth/token", "GET", null, token);
};
